import React, { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";

import ItemNotFoundModal from "../modals/ItemNotFoundModal";
import {
  NotFoundError,
  UnknownError,
  HttpError,
  FetchMultipleOrderDatas,
} from "../functions/ApiCalls";
import { Trans } from "react-i18next";

import { useTranslation } from "react-i18next";

function TrackMultipleFormPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isTrackingNumberError, setTrackingInputError] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);
  
  const { t } = useTranslation();

  const MaxLines : Number = 20; // #6144
  
  const handleInputChange = useCallback((event) => {
    const inputValue = event.target.value;
    const lines = inputValue.split("\n");

    if (event.keyCode === 13 && lines.length === MaxLines - 1) {
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setTrackingInputError(false);
    setErrorDetails (null);// clear the error details.
    setIsLoading(false);
    const formData = new FormData(event.target);
    var rawTrackingNumbers = formData.get("trackingNumbers").trim();

    if (rawTrackingNumbers === "") {
      setTrackingInputError(true);
      return;
    }

    setIsLoading(true);

    // #6144 - Limit the list to 260 characters, since that's the max the server can support (see UrlSegmentMaxLength in IIS)
    if (rawTrackingNumbers.length > 260)
    {
      console.log("Warning, tracking #s exceed server's limit of 260 characters, truncating list...");
      rawTrackingNumbers = rawTrackingNumbers.substring(0, 260);
    }
    
    const trackingNumbers = rawTrackingNumbers
      .split("\n")
      .map((number) => number.trim());
    
    
    const response = await FetchMultipleOrderDatas(trackingNumbers);

    if (response instanceof UnknownError) {
      setErrorDetails (response.errorDetails);

      handleOpenErrorModal();
      setIsLoading(false);
      return;
    }

    if (response instanceof NotFoundError) {
      //setErrorDetails ('Actually not found');
      setErrorDetails (response.errorDetails);
      
      handleOpenErrorModal();

      setIsLoading(false);
      return;
    }

    if (response instanceof HttpError) {
      setErrorDetails (response.errorDetails);

      handleOpenErrorModal();

      setIsLoading(false);
      return;
    }

    navigate(`/trackMultiple/${encodeURIComponent(trackingNumbers.join(","))}`);
  };

  const handleOpenErrorModal = () => {
    setIsErrorModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <div className="container d-flex justify-content-center mb-5">
        <div className="form-phone d-inline-flex justify-content-evenly align-items-center mt-5">
          <a href="tel:18886412142">P: 1.888.942.7753 (Canada)</a>
          <a href="tel:18889427753">P: 1.888.672.7753 (USA)</a>
        </div>
      </div>
      <div className="container justify-content-center d-flex">
        <form onSubmit={handleSubmit}>
          <fieldset
            disabled={isLoading}
            className="d-flex justify-content-center flex-column"
          >
            <div className="row text-center mb-5">
              <h1>
                <Trans i18nKey="TrackMultipleFormPage.title">
                  Track Multiple Orders
                </Trans>
              </h1>

              <p>
                <Trans i18nKey="TrackMultipleFormPage.clickHereToGoBack">
                  Click <Link to="/">here</Link> to go back to Quick Track.
                </Trans>
              </p>
            </div>
            <div className="mb-3">
              <label htmlFor="trackingNumbers" className="form-label">
                <Trans i18nKey="TrackMultipleFormPage.enterMultiple">
                  Enter up to {{MaxLines}} tracking numbers, one per line.
                </Trans>
              </label>

              <textarea
                rows={5}
                cols={30}
                placeholder={t("TrackMultipleFormPage.refrenceNumbersHere")}
                style={{ height: "200px" }}
                type="text"
                className={
                  isTrackingNumberError
                    ? "form-control is-invalid"
                    : "form-control"
                }
                autoComplete="off"
                id="trackingNumbers"
                name="trackingNumbers"
                aria-describedby="trackingNumbers"
                required={true}
                autoFocus={true}
                onKeyDown={handleInputChange}
              />

              <div className="invalid-feedback">
                <Trans i18nKey="TrackMultipleFormPage.enterOrderError">
                  Please enter a order or reference number.
                </Trans>
              </div>
            </div>

            <div>
              <button
                type="reset"
                className="btn btn-link p-0 m-0 text-secondary"
              >
                <Trans i18nKey="Generic.reset">Reset</Trans>
              </button>
            </div>

            <button type="submit" className="btn btn-primary flex-grow-1">
              {isLoading ? "..." : t("Generic.submit")}
            </button>
            <div className="d-flex justify-content-between">
              <a
                className="text-secondary"
                target="_blank"
                href="https://goapple.appleexpress.com/"
                rel="noreferrer"
              >
                <Trans i18nKey="Generic.clientLogin">Client Login</Trans>
              </a>
              <a
                className="text-secondary"
                target="_blank"
                href="http://www.appleexpress.com/vendorportal"
                rel="noreferrer"
              >
                <Trans i18nKey="Generic.agentLogin">
                  Agent Login
                </Trans>
              </a>
            </div>
          </fieldset>
        </form>
      </div>
      <ItemNotFoundModal isOpen={isErrorModalOpen} onClose={handleCloseModal} errorDetails={errorDetails} />
    </>
  );
}

export default TrackMultipleFormPage;
