import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { useLoaderData } from "react-router-dom";
import TrackPackageButton from "../components/TrackButton";
import { Trans } from "react-i18next";

function MultipleOrderDetailsPage() {
  const { orders } = useLoaderData();

  return (
    <Container fluid className="text-center p-0">
      <TrackPackageButton />
      <h1 className="mt-3">
        <Trans i18nKey="MultipleOrderDetails.header">
          Multiple Shipment Tracking Summary
        </Trans>
      </h1>
      <Row className="mt-5 ms-0 me-0 d-flex justify-content-center">
        <Col xs={12} lg={7}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{'text-align': 'left'}}>
                  <Trans i18nKey="Generic.trackingNumber">
                    Tracking Number
                  </Trans>
                </th>
                <th style={{'text-align': 'left'}}>
                  <Trans i18nKey="Generic.referenceNumber">

                    Reference Number
                  </Trans>
                </th>
                <th style={{'text-align': 'left'}}>
                  <Trans i18nKey="Generic.status">

                    Status
                  </Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.appleTrackingNumber}>
                  <td style={{'text-align': 'left'}}>
                    <Link to={`/orderNumber/${order.appleTrackingNumber}`}>
                      {order.appleTrackingNumber}
                    </Link>
                  </td>
                  <td style={{'text-align': 'left'}}>{order.reference}</td>
                  <td style={{'text-align': 'left'}}>{order.currentStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
}

export default MultipleOrderDetailsPage;
